import React,{lazy} from "react";

import Header from "../site/Header";
import Styled from "./styles";

// import HeroBanner from "./HeroBanner";
import Works from "./Works";
import Contact from "./Contact";
import Services from "./Services";
import Footer from "./Footer";
import Testimonials from "./Testimonials";
const HeroBanner =lazy(()=>import('./HeroBanner'))
const Site = () => {

  return (
    <Styled>
      <Header />
      <HeroBanner />
      <Works />
      <Services />
      <Testimonials/>
      <Contact />
      <Footer/>
    </Styled>
  );
};

export default React.memo(Site)
