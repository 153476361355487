import React from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "../../Admin";

const AddResults = () => {
  return (
    <Admin title="Add Results">
      <Styled>
          <Grid container >
            <Grid item xs={12} sm={12} md={12}>
            </Grid>
          </Grid>
      </Styled>
    </Admin>
  );
};

export default AddResults;
