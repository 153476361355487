export const GetEnquiries = {
  formId: "getEnquiries",
  formInp: [
    {
      inp: {
        type: "submit",
        label: "Autoload",
        loaderLabel: "Adding data...",
        name: "autoload",
        id: "autoload",
        variant:"contained",
        className:"btn-std1",
        fullWidth: true,
        service:{
          url:process.env.REACT_APP_API_SERVICE_URL+`api/get_data`,
          params:{schema: "enquiriesSchema"},
          method:'GET',
          Autoload:true,
        }
      },
      grid: 6,
    },
  ],
};
