import React, { useState } from "react";
import Styled from "./styles";
import GameBoard from "./GameBoard";
import ResultArea from "./ResultArea";
import { Grid } from "@mui/material";

const GameGround = () => {
  const initialGround = [
    {
      11: "",
      12: "",
      13: "",
    },
    { 21: "", 22: "", 23: "" },
    { 31: "", 32: "", 33: "" },
  ];
  const [playGroundElems, setPlayGroundElems] = useState(initialGround);
  const [usersTurn, setUsersTurn] = useState({ position: "00", pressed: "X" });
  const [winner, setWinner] = useState(false);
  const updateEntry = (boxTocheck) => {
    let currPlayGroundElems = playGroundElems;
    const boxArray = boxTocheck.split("");
    const newUserTurn = usersTurn?.pressed === "X" ? "O" : "X";
    setUsersTurn({ position: boxTocheck, pressed: newUserTurn });
    currPlayGroundElems[boxArray[0] - 1][boxTocheck] = newUserTurn;
    setPlayGroundElems(currPlayGroundElems);
  };
  const newGame = () => {
    setPlayGroundElems(initialGround);
    setUsersTurn({ position: "00", pressed: "X" });
    setWinner(false);
  };
  const roundWinner = (win) => {
    setWinner(win);
  };
  return (
    <Styled>
      <Grid container columns={{ xs: 4, md: 12 }}>
        <Grid xs={6} md={6}>
          <GameBoard
            dataRows={playGroundElems}
            onClick={updateEntry}
            contentAlign={"center"}
            winner={winner}
          />
        </Grid>{" "}
        <Grid xs={6} md={6}>
          <ResultArea
            playGroundElems={playGroundElems}
            usersTurn={usersTurn}
            updateComputerEntry={updateEntry}
            newGame={newGame}
            roundWinner={roundWinner}
          />
        </Grid>
      </Grid>
    </Styled>
  );
};

export default GameGround;
