import styled from "styled-components/macro";

export default styled.div`
  .about-container {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    text-align: center;
    padding: 20px 24px 50px 24px;
  }
  .align-center {
    text-align: center;
  }
  span.highlt-text {
    color: ${({ theme }) => theme?.colors?.["2"]};
  }
  .zero-padding{
    padding:0 !important;
  }
  .about-img {
   width:100%;
   border-radius:20px;
   border:1px solid ${({ theme }) => theme?.colors?.["1"]};
   box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
  }
  .aboutImg-text span{
    font-size:${({ theme }) => theme?.fonts?.["12"]};
  }
`;
