import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Styled from "./styles";
import { Button } from "@mui/material";

export default function DataTable({
  dataRows,
  headRows = false,
  contentAlign = "left",
  onClick=()=>{}
}) {
  return (
    <Styled>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headRows?.map((value,index) => (
                <TableCell sm={100} key={index}>{value}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataRows?.map((obj, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {Object.keys(obj).map((keys, i) => (
                  <>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: contentAlign, width: "0%" }}
                      key={i}
                    >
                      {obj?.[keys]}
                    </TableCell>
                    {Object.keys(obj)?.length - 1 === i && (
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: contentAlign, width: "0%" }}
                      >
                        <Button
                          className="primary"
                          onClick={()=>onClick(obj["_id"],'e')}
                         
                        >
                          {" "}
                          Edit
                        </Button>
                        <br></br>
                        <Button
                          className="secondary"
                          onClick={()=>onClick(obj["_id"],'d')}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    )}
                  </>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled>
  );
}
