let timeOut;
export function getCurrentDate() {
  const d = new Date();
  return d.getFullYear();
}
export function handlerResponseAlerts(errorParams, dfResponse) {
  if (dfResponse?.status === 200) {
    if (errorParams) {
      const splitErrorParams = errorParams?.split(":");
      let resultsData = dfResponse;
      splitErrorParams[0]?.split(".")?.map((val, i) => {
        if (i === 0) {
          return false;
        }
        resultsData = resultsData?.[val];
      });
      if (resultsData.length > parseInt(splitErrorParams[1])) {
        return 3;
      } else {
        return 1;
      }
    } else {
      return 2;
    }
  } else {
    return 4;
  }
}
export function getAlertData(p) {
  let severity;
  let message;
  switch (p) {
    case 1:
      severity = "error";
      message = "No record found!";
      return { severity, message };
    case 2:
      severity = "success";
      message = "Request sent successfully!";
      return { severity, message };
    case 3:
      severity = "success";
      message = "Success!";
      return { severity, message };
    case 4:
      severity = "error";
      message = "Sorry didn't match any data. Failed!!";
      return { severity, message };
    default:
      severity = "error";
      message = "Something went wrong, please try again!";
      return { severity, message };
  }
}
export function inputTextThrottle(cb,e) {
  if(timeOut){
    clearTimeout(timeOut);
  }
  timeOut = setTimeout(()=>{
    cb(e);
  }, 400);
}
