import React from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "../../Admin";
import TableDataGrid from "../../../common/DataGrid";
import { GetEnquiries } from "../../../common/formJson/Admin/Autoload/GetEnquiries";
import DynamicForm from "../../DynamicForm";
import { useSelector } from "react-redux";
const columns= [
   { field: "_id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "projectDetails",
    headerName: "Project Details",
    width: 500,
    editable:true
  },
];
const Enquiries = () => {
  const enquiries = useSelector(({ dynamicForms }) => dynamicForms?.getEnquiries?.results?.data);
  return (
    <Admin title="Enquiries">
      <Styled>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <DynamicForm autoload={GetEnquiries?.formInp[0]?.inp?.service?.Autoload} formObj={GetEnquiries} />
            {enquiries?.length&&
            <TableDataGrid columns={columns} rows={enquiries}/>}
          </Grid>
        </Grid>
      </Styled>
    </Admin>
  );
};

export default Enquiries;
