import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import Divider from "../../common/Divider";
import { useSelector } from "react-redux";
import IconComponent from "../../common/Icons";
import { getCurrentDate } from "../../utils/Utils";
import { HashLink } from "react-router-hash-link";
import whatsapp from '../../assets/whatsapp.png';
const Footer = () => {
  const theme = useSelector(({ config }) => config?.theme);
  const socialLinks = [
    {
      icon: "FaFacebook",
      link:"https://www.instagram.com/iamsandeepvishwakarma/"
    },
    {
      icon: "FaTwitter",
      link:"https://twitter.com/iamsandeepvishw"
    },
    {
      icon: "FaInstagram",
      link:"https://www.instagram.com/iamsandeepvishwakarma/"
    },
  ];

  return (
    <Styled>
      <MainContainer padding="0">
        <Grid container className="footer-container">
          <Grid item xs={12} sm={12}>
            <Divider />
            <Grid container className="copyright-container">
              <Grid xs={12} sm={3} className="copyr-1">
              Copyright © SV {getCurrentDate()} All Rights Reserved.
              </Grid>
              <Grid xs={12} sm={6} className="copyr-2">
                <span>Designed & Developed By</span> 
                <div className="nav-link-container">
                  <HashLink
                    smooth
                    to={'/#about'}
                    sx={{ color: "#fff" }}
                    className="nav-links"
                  >
                    Sandeep Vishwakarma
                  </HashLink>
                  <div className="highlight-nav"></div>
                </div>
              </Grid>
              <Grid xs={12} sm={3} className="copyr-3">
                <div className="footer-links">
                  {socialLinks?.map(({ icon,link }) => {
                    return (
                      <a href={link} className="social-link" key={icon}>
                        <IconComponent
                          iconName={icon}
                          iconColor={theme?.colors?.["2"]}
                          iconFontSize={theme?.fonts?.["3"]}
                        />
                      </a>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainContainer>
      <a href="https://wa.me/8286813508" title="chat on whatsapp" ><img src={whatsapp} className="whatsapp-icon"/> </a>
    </Styled>
  );
};

export default Footer;
