import React, { useState } from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "..";
import DynamicForm from "../../DynamicForm";
import { AddAndUpdateTestimonials } from "../../../common/formJson/Admin/AddAndUpdateTestimonials";
import { GetTestimonials } from "../../../common/formJson/Admin/Autoload/GetTestimonials";
import { useSelector } from "react-redux";
import DataTable from "../../../common/DataTable";
import axios from "axios";
const columns = ["ID", "Client", "Client feedback", "Client Image", "Action"];

const ManageTestimonials = () => {
  const [editRecord, setEditRecord] = useState();
  const testimonials = useSelector(
    ({ dynamicForms }) => dynamicForms?.getTestimonials?.results?.data
  );
  const onClickRedirect = async (id, actionCode) => {
    if (actionCode === "d") {
      const deleteData = testimonials?.find((val) => {
        return val?._id === id;
      });
      const formData = {
        id: id,
        schema: "testimonialsSchema",
        imgid: deleteData?.client_image,
      };
      const headers = {
        params: formData,
      };
      const response = await axios.get(
        process.env.REACT_APP_API_SERVICE_URL + `api/delete_record`,
        headers
      );
      if (response) {
        alert("record deleted!");
        window.location.reload();
      }
    } else if (actionCode === "e") {
      const editData = testimonials?.find((val) => {
        return val?._id === id;
      });
      // console.log(editData,'editData')
      setEditRecord(editData);
    }
  };
  return (
    <Admin title="Manage Testimonials">
      <Styled>
        <DynamicForm
          autoload={GetTestimonials?.formInp[0]?.inp?.service?.Autoload}
          formObj={GetTestimonials}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <DynamicForm
              formObj={AddAndUpdateTestimonials}
              editRecord={editRecord}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <DataTable
              dataRows={testimonials}
              headRows={columns}
              onClick={onClickRedirect}
            />
          </Grid>
        </Grid>
      </Styled>
    </Admin>
  );
};

export default ManageTestimonials;
