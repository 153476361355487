export const InitailizeDynamicForm =
  (fieldsObj, formID, inputValObjs, validationPassed) =>{
    const actionObj = { fieldsObj, formID, inputValObjs, validationPassed };
    return { type: "SET_DF", ...actionObj };
  }
  
export const updateDynamicFormFields = (fieldName, value, formID) => {
  const actionObj = { fieldName, value, formID };
  return { type: "UPDATE_DFF", ...actionObj };
};
export const setSubmitForm = (formID) => {
  return { type: "SUBMIT_FLAG", formID };
};
export const passedFormValidation = (value, fieldName, formID) => {
  const actionObj = { value, fieldName, formID };
  return { type: "PASSED_FORMVALIDATION", ...actionObj };
};
export const emailServiceCall =
  (fieldsObj, formID) => {
    return (dispatch, getState, api) => {
      dispatch({ type: "LOADING_DATA", formID });
      return api.sendEmail(fieldsObj, formID).then(
        (result) => {
          const actionObj = { result, formID };
          dispatch({ type: "DYNAMICFORM_SC", ...actionObj });
        },
        (error) => {
          const result = error;
          const actionObj = { result, formID };
          dispatch({ type: "DYNAMICFORM_SC", ...actionObj });
        }
      );
    };
  }

export const dynamicFormServiceCall = (
  fieldsObj,
  method,
  url,
  formID=false,
  formParams = false
) => {
  return  (dispatch, getState, api) => {
    dispatch({ type: "LOADING_DATA", formID });
    return api.dynamicFormService(fieldsObj, method, url, formID, formParams).then(
      (result) => {
        const actionObj = { result: result?.data, formID };
        dispatch({ type: "DYNAMICFORM_SC", ...actionObj });
      },
      (error) => {
        const result = error;
        const actionObj = { result, formID };
        dispatch({ type: "DYNAMICFORM_SC", ...actionObj });
      }
    );
  };
};
