import {errorObjs} from './CommonObj'

export const LoginFormObjs = {
  formId: "loginForm",
  formInp: [
    {
      inp: {
        type: "text",
        label: "Enter username",
        name: "username",
        id: "username",
        fullWidth: true,
        required: true,
        helperText: errorObjs,
      },
      grid: 12,
    },
    {
      inp: {
        type: "password",
        label: "Enter password",
        name: "password",
        id: "password",
        fullWidth: true,
        required: true,
        helperText: errorObjs,
        maxLength: 15,
      },
      grid: 12,
    },
    {
      inp: {
        type: "alert",
        errorParams:'results.data:0'
      },
      grid: 12,
    },
    {
      inp: {
        type: "submit",
        label: "Sign in",
        loaderLabel: "logging in...",
        name: "login_submit",
        id: "login_submit",
        variant:"contained",
        className:"btn-std1",
        fullWidth: true,
        service:{
          schema:'loginSchema',
          url:process.env.REACT_APP_API_SERVICE_URL+`api/login_submit`,
          method:'POST',
          gotoLink:'/admin/dashboard',
          errorParams:'results.data:0'
        }
      },
      grid: 12,
    },
  ],
};
