import React, { useState, useEffect,memo } from "react";
import Styled from "./styles";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";

const FileUploadInput = ({
  helperText,
  formId,
  name,
  label,
  InputLabelProps = {},
  ...props
}) => {
  const [error, setError] = useState({ errorFlag: false, errorType: false });
  const dispatch = useDispatch();
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );
  const onChangeHandler = (e) => {
    let value = "";
    if (!e?.target?.name) {
      value = e.target.files[0];
    } else {
      name = e?.target?.name;
      value = e?.target?.value;
    }
    dispatch(action.passedFormValidation(false, name, formId));
    let errorFlag = false;
    let errorType = false;
    dispatch(action.updateDynamicFormFields(name, value, formId));
    if (dynamicForms?.fieldsObj?.[name]?.required && !value) {
      errorFlag = true;
      errorType=1;
    } else {
      errorFlag = false;
      errorType=2;
    }
    setError({ errorFlag: errorFlag, errorType: errorType });
    if (!errorFlag) {
      dispatch(action.passedFormValidation(!error?.errorFlag, name, formId));
    }
  };

  useEffect(() => {
    if (dynamicForms?.submit === true) {
      const e = {};
      e.target = {
        name: dynamicForms?.fieldsObj?.[name].name,
        value: dynamicForms?.inputValObjs?.[name],
      };
      onChangeHandler(e);
    }
  }, [dynamicForms?.submit]);

  const showPreview=()=>{
    if(dynamicForms?.inputValObjs?.[name]){
      if(typeof dynamicForms?.inputValObjs?.[name]==='string'){
        return `${process.env.REACT_APP_DRIVE_FILE}${dynamicForms?.inputValObjs?.[name]}`;
      }else{
        return URL.createObjectURL(dynamicForms?.inputValObjs?.[name]);
      }
    }
  }
  return (
    <Styled >
      <input onChange={onChangeHandler} {...props} />
      {dynamicForms?.inputValObjs?.[name] && (
        <img
          height={'auto'}
          width= {"200px"}
          src={showPreview()}
        />
      )}
      <br></br>
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" className={`${error?.errorFlag?'error-input':''}`}>
          {label}
        </Button>
        {error?.errorFlag && <span className="error-span">{helperText?.[error?.errorType]} </span>}
      </label>
    </Styled>
  );
};

export default memo(FileUploadInput);
