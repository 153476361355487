export const GetWorks = {
  formId: "getWorks",
  formInp: [
    {
      inp: {
        type: "submit",
        label: "Autoload",
        name: "autoload",
        id: "autoload",
        service:{
          url:process.env.REACT_APP_API_SERVICE_URL+`api/get_data`,
          params:{schema: "worksSchema"},
          method:'GET',
          Autoload:true,
        }
      },
      grid: 6,
    },
  ],
};
