import React, { useEffect, useState } from "react";
import Styled from "./styles";
import GameGround from "./GameGround";
import Container from "@mui/material/Container";
import { Button, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch,useSelector } from "react-redux";
import action from '../../redux/action'

const playOptions = ["Play With Friend", "Play With Computer"];
const TicTacToe = () => {
  const dispatch=useDispatch();
   const [playWith, setPlayWith] = useState();
   const gameData=useSelector((state)=>state?.manageGameData?.playWith)

  const playWithHandler = (valText) => {
    const data=valText.split(" ")[2];
    setPlayWith(data);
    dispatch(action.playWith(data));
  };

  useEffect(()=>{
    setPlayWith(gameData);
  },[gameData])
  return (
    <Styled>
      <Container>
        {!playWith ? (
          <Stack direction="row" spacing={2} className="play-with-buttons">
            {playOptions?.map((val, index) => (
              <Button
                variant="outlined"
                size="large"
                onClick={() => playWithHandler(val)}
                key={index}
              >
                {val}
              </Button>
            ))}
          </Stack>
        ) : (
          <GameGround />
        )}
      </Container>
    </Styled>
  );
};

export default TicTacToe;
