import { createStore,applyMiddleware } from "redux";
import rootReducers from "./redux/reducer";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import api from './serviceCalls/apiCalls'

const store = createStore(
  rootReducers,
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument(api))
  )
);

export default store;
