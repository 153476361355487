import styled from "styled-components/macro";

export default styled.div`
  width: 100%;

  .MuiMobileStepper-root {
    display: inline-block;
    background-color: ${({ theme }) => theme?.colors?.["8"]};
  }
  .MuiMobileStepper-dot {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    width: 15px;
    height: 15px;
  }
  .MuiMobileStepper-dotActive {
    background-color: ${({ theme }) => theme?.colors?.["3"]};
  }
  .feedback {
    @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
      padding: 10px;
    }
   
    box-shadow: 0px 0px 5px 5px #333333;
    .feedback-text {
      @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
        font-size: 15px;
      }
      font-size: ${({ theme }) => theme?.fonts?.["3"]};
    }
    .feedback-name {
      padding-top: 30px;
      font-size: ${({ theme }) => theme?.fonts?.["1"]};
      color: ${({ theme }) => theme?.colors?.["5"]};
      .img-avt {
        display: inline-flex;
        span {
          margin: auto;
          padding-left: 10px;
        }
        img {
          border-radius: 50%;
          width: auto;
          height:60px;
        }
      }
    }
  }
  .slider-container {
    @media (min-width: ${({ theme }) => theme?.deviceSize?.desktop}) {
      width: 70%;
    }
  }
  .slick-dots {
    button:before {
      background-color: ${({ theme }) => theme?.colors?.["2"]};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
  }
  .slick-prev,
  .slick-next {
    width: fit-content;
    height: auto;
  }
  .slick-slide {
    > div {
      margin: 10px 100px;
    }
    @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
      > div {
        margin: 10px;
      }
    }
  }
  .slick-track {
    margin: auto;
    align-items: center;
    display: flex;
  }
`;
