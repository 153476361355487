import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import About from "../About";
import { GetWorks } from "../../common/formJson/Autoload/GetWorks";
import DynamicForm from "../DynamicForm";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import defaultImg from "../../assets/images/default-img.jpg";

const Works = () => {
  const works = useSelector(
    ({ dynamicForms }) => dynamicForms?.getWorks?.results?.data
  );

  return (
    <Styled>
      <DynamicForm
        autoload={GetWorks?.formInp[0]?.inp?.service?.Autoload}
        formObj={GetWorks}
      />
      <MainContainer padding="40px 0px 30px">
        <div className="triangle-parent-div">
          <div className="triangle-up"></div>
        </div>
        <About />
        <Grid container className="works-container" id="works">
          <SubHeading fpart="My works" spart="Recent works" sColSwap={true} />
          {works ? (
            works?.map((val, index) => {
              const imageUrl = `${process.env.REACT_APP_DRIVE_FILE}${val?.image}`;
              return (
                <Grid
                  item
                  xs={12}
                  sm={12 / works?.length}
                  md={12 / works?.length}
                  key={index}
                >
                  <div className="work-ele">
                    <img
                      src={imageUrl}
                      className="work-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultImg;
                      }}
                    />

                    <div className="work-content">
                      <div className="inner">
                        <div>{val?.title}</div>
                        <div className="site-link">
                          {" "}
                          <a
                            className="btn-std1"
                            href={val?.link}
                            target="_blank"
                          >
                            Click to view
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })
          ) : (
            <Loader />
          )}
        </Grid>
      </MainContainer>
    </Styled>
  );
};

export default Works;
