import React,{useState} from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import Admin from "..";
import DynamicForm from "../../DynamicForm";
import { AddAndUpdateWorks } from "../../../common/formJson/Admin/AddAndUpdateWorks";
import { GetWorks } from "../../../common/formJson/Autoload/GetWorks";
import { useSelector } from "react-redux";
import DataTable from "../../../common/DataTable";
import axios from "axios";
const columns = ["ID", "Title", "Description", "Image", "Link", "Action"];

const ManageWorks = () => {
  const [editRecord,setEditRecord]=useState();
  const works = useSelector(
    ({ dynamicForms }) => dynamicForms?.getWorks?.results?.data
  );
  const onClickRedirect = async (id, actionCode) => {
    if (actionCode === "d") {
      const deleteData=works?.find((val)=>{
        return val?._id===id;
  })
      const formData = { id: id, schema: "worksSchema",imgid:deleteData?.image };
      const headers = {
        params: formData,
      };
      const response = await axios.get(
        process.env.REACT_APP_API_SERVICE_URL + `api/delete_record`,
        headers
      );
      if (response) {
        alert("record deleted!");
        window.location.reload();
      }
    }else if(actionCode==='e'){
      const editData=works?.find((val)=>{
            return val?._id===id;
      })
      setEditRecord(editData);
    }
  };
  return (
    <Admin title="Manage Works">
      <Styled>
        <DynamicForm
          autoload={GetWorks?.formInp[0]?.inp?.service?.Autoload}
          formObj={GetWorks}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <DynamicForm formObj={AddAndUpdateWorks} editRecord={editRecord} />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <DataTable
              dataRows={works}
              headRows={columns}
              onClick={onClickRedirect}
            />
          </Grid>
        </Grid>
      </Styled>
    </Admin>
  );
};

export default ManageWorks;
