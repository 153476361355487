import React from "react";
import Slider from "react-slick";
import Styled from "./styles";
import defaulImg from "../../assets/images/default-client.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselNew({ dataRows }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    arrows:false
  };
  return (
    <Styled className="slider-container">
  
      <Slider {...settings}>
      {dataRows?.map((step, index) => (
            
              <div className="feedback" key={step.client_name}>
                  <div className="feedback-name">
                    <div className="img-avt">
                      <img
                        src={
                          step?.client_image !== "undefined"
                            ? step?.client_image !== ""
                              ? process.env.REACT_APP_DRIVE_FILE +
                                step?.client_image
                              : defaulImg
                            : defaulImg
                        }
                      />{" "}
                      <span>{step?.client_name}</span>
                    </div>
                  </div>
                  <p className="feedback-text">“{step.feedback_desc}”</p>
                </div>
          ))}
      </Slider>
    </Styled>
  );
}

export default CarouselNew;
