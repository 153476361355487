import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { handlerResponseAlerts, getAlertData } from "../../utils/Utils";

const AlertComponent = ({ formId, errorParams }) => {
  const dfResponse = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]?.results
  );
  const [showError,setShowError]=useState(false);

  const alertNumber = handlerResponseAlerts(errorParams, dfResponse);
  const { severity, message } = getAlertData(alertNumber);
  useEffect(()=>{
    if(dfResponse?.status){
      setShowError(true);
      setTimeout(()=>{
        setShowError(false);
      },5000)
    }
  },[dfResponse])
  return (
    dfResponse?.status&&showError && (
      <Styled>
        <Alert severity={severity}>{message}</Alert>
      </Styled>
    )
  );
};

export default AlertComponent;
