import styled from "styled-components/macro";

export default styled.div`
  button.btn-std {
    text-transform: capitalize;
    color:${({ theme }) => theme?.colors?.["0"]};
    background-color:${({ theme }) => theme?.colors?.["2"]};
    border-radius: 30px;
    border:0;
    transition: all 300ms ease;
    font-family: ${({ theme }) => theme?.fontFamily?.["0"]};
    color: #fff;
    font-size: 16px;
    padding: 15px;
    &:hover{
      background-color:${({ theme }) => theme?.colors?.["3"]};
      padding: 15px 40px;
      
    }
  }
  button.btn-std1 {
    text-transform: capitalize;
    color:${({ theme }) => theme?.colors?.["0"]};
    background-color:${({ theme }) => theme?.colors?.["2"]};
    transition: all 300ms ease;
    font-family: ${({ theme }) => theme?.fontFamily?.["0"]};
    color: #fff;
    font-size: 16px;
    padding: 15px;
    &:hover{
      background-color:${({ theme }) => theme?.colors?.["3"]};
      padding: 15px 40px;
    }
  }

`;
