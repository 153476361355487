import Styled from "./styles";
import { Grid } from "@mui/material";

const SubHeading = ({ fpart, spart,sColSwap=0 }) => {
  return (
    <Styled sColSwap={sColSwap}>
      <Grid item xs={12} sm={12}>
        <div className="sub-heading">
          <h4 className="sub-text">{fpart}</h4>
          <h3 className="second-heading">{spart}</h3>
        </div>
      </Grid>
    </Styled>
  );
};

export default SubHeading;
