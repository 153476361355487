import styled from 'styled-components/macro';

export default styled.div`
padding-top:20px;
text-align:center;
font-size:50px;
fort-weight:bold;
div.score-card{
    font-size:30px;
}
`