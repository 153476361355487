import React,{memo} from "react";
import TextFieldInput from "./TextFieldInput";
import FileUploadInput from "./FileUploadInput";
import ButtonInput from "./ButtonInput";
import RichTextEditorInput from "./RichTextEditorInput";

const InputFields = ({ type, ...props }) => {
  switch (type) {
    case "text":
    case "password":
    case "email":
    case "number":
      return <TextFieldInput type={type} {...props} />;
      case "file":
        return <FileUploadInput type={type} {...props} />;
      case "rte":
      return <RichTextEditorInput  {...props} />;
    case "button":
    case "submit":
      return <ButtonInput type={type} {...props} />;
    default:
      return <></>;
  }
};

export default memo(InputFields);
