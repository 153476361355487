import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import CarouselNew from "../../common/CarouselNew";
import { GetTestimonials } from "../../common/formJson/Admin/Autoload/GetTestimonials";
import DynamicForm from "../DynamicForm";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
const testimonies = [
  {
    title: "Web Development",
    desc: "I specialize in designing and developing professional websites that are tailored to meet your unique needs and goals. From initial strategy and planning, to launch and ongoing support, I'm here to help every step of the way.",
  },
  {
    title: "Web Designing",
    desc: "I design websites that look and work great on all devices, from desktops and laptops to smartphones and tablets.",
  },
  {
    title: "Web App Maintenance",
    desc: "I offer ongoing maintenance and support services to keep your website running smoothly and secure, so you can focus on growing your business.",
  },
];
const Testimonials = () => {
  // const theme = useSelector(({ config }) => config?.theme);
  const testimonials = useSelector(
    ({ dynamicForms }) => dynamicForms?.getTestimonials?.results?.data
  );
  return (
    <Styled id="testimonials">
      <DynamicForm
        autoload={GetTestimonials?.formInp[0]?.inp?.service?.Autoload}
        formObj={GetTestimonials}
      />
      <MainContainer padding="20px 0 40px 0">
        <Grid container className="services-container">
          <SubHeading fpart="What They Think" spart="Testimonials" />
          {testimonials?.length?<CarouselNew dataRows={testimonials}/>:<Loader/>}
          
        </Grid>
      </MainContainer>
    </Styled>
  );
};

export default Testimonials;


