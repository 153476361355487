import Styled from "./styles";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import { getCurrentDate } from "../../utils/Utils";
import aboutImg from "../../assets/images/about-me.jpg";

const About = () => {
  return (
    <Styled id="about">
      <Grid container className="about-container">
        <SubHeading fpart="Who we are" spart="About us" sColSwap={true} />
        {/* <Grid item xs={12} sm={12} md={1} className="zero-padding"></Grid> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          className="about-text"
          textAlign="left"
        >
          Hello! <span className="highlt-text">I'm a Mumbai based Full Stack Software Developer with{" "}
          {getCurrentDate() - 2015}+ years of experience in the field.</span> My
          passion lies in creating high-quality software that solves real-world
          problems and delivers value to users. Throughout my career, I have
          worked on a wide range of projects, from small-scale web applications
          to large-scale enterprise systems and <span className="highlt-text">I have worked in small scale startups to top
           companies of India like Mphasis Ltd.</span>
          <p>
            My technical skills include expertise in multiple programming
            languages, frameworks, and tools. I have a deep understanding of
            software development principles and methodologies, which allows me
            to deliver robust and reliable software solutions. <span className="highlt-text">Some of the
            technologies that I'm proficient in React Js, Node Js, PHP, HTML,
            CSS and JavaScript, among others.</span>
          </p>{" "}
          <p>
            Apart from technical expertise, I possess strong problem-solving and
            analytical skills. I am able to break down complex problems into
            smaller components and come up with elegant solutions that are easy
            to maintain and scale.
          </p>{" "}
          <p>
            {" "}
            Throughout my career, I have worked with various clients and
            stakeholders, and I always strive to exceed their expectations.
            Whether you need a new software application developed or an existing
            one improved, I am confident in my ability to deliver quality
            results that meet your specific needs.
          </p>
          <p>
            Thank you for taking the time to learn more about me. <span className="highlt-text">If you have
            any questions or would like to discuss your software development
            needs, please don't hesitate to get in touch.</span>
          </p>
        </Grid>

    

        <Grid item xs={12} sm={12} md={4}>
          <img src={aboutImg} className="about-img"/>
          <br></br>
          <div className="aboutImg-text">
            <strong>Sandeep Vishwakarma</strong>
            <br></br>{" "}
            <span className="highlt-text">{"{Senior Software Developer}"}</span>
          </div>
        </Grid>
      </Grid>
    </Styled>
  );
};

export default About;
