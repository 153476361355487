import styled from "styled-components/macro";

export default styled.div`
  .play-with-buttons {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    > button {
      height: 60px;
      border: 2px solid;
    }
  }
`;
