import * as React from "react";

import Styled from "./styles";

export default function GameBoard({
  dataRows,
  onClick = () => {},
  winner
}) {
  return (
    <Styled>
      {dataRows.map((obj, i) => (
        <div>
          {Object.keys(obj).map((keys, i) => {
            return (
              <div>
                <button
                  className="cols"
                  key={i}
                  disabled={obj?.[keys]||winner}
                  onClick={() => onClick(keys)}
                >
                  <div className="content">{obj?.[keys]}</div>
                </button>
              </div>
            );
          })}
        </div>
      ))}
    </Styled>
  );
}
