import React, { useState, useEffect, memo } from "react";
import "./App.css";
import TicTacToe from "./games/TicTacToe";
import Site from "./site";
import Login from "./site/Login";
import AddResults from "./site/Admin/AddResults";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import action from "./redux/action";
import Config from "./Config";
import { ThemeProvider } from "styled-components";
import Dashboard from "./site/Admin/Dashboard";
import Enquiries from "./site/Admin/Enquiries";
import ManageWorks from "./site/Admin/ManageWorks";
import ManageTestimonials from "./site/Admin/ManageTestimonials";
import PageNotFound from "./site/PageNotFound";
import ProtectedRoutes from "./ProtectedRoutes";
function App() {
  const [theme, setTheme] = useState("0");
  const dispatch = useDispatch();

  const globalTheme = useSelector(({ config }) => config?.theme);

  useEffect(() => {
    if (theme) {
      setTheme(Config?.Theme[theme]);
      dispatch(action.updateTheme(Config?.Theme[theme]));
    }
  }, []);

  return (
    <Router>
      <ThemeProvider theme={globalTheme || {}}>
        <Routes>
          <Route exact path="/" element={<Site />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/games" element={<TicTacToe />} />
          <Route exact path="*" element={<PageNotFound />} />

          <Route
            exact
            path="/admin/add-results"
            element={
              <ProtectedRoutes>
                <AddResults />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            exact
            path="/admin/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            exact
            path="/admin/enquiries"
            element={
              <ProtectedRoutes>
                <Enquiries />
              </ProtectedRoutes>
            }
          />
          <Route
            exact
            path="/admin/manage-works"
            element={
              <ProtectedRoutes>
                <ManageWorks />
              </ProtectedRoutes>
            }
          />
          <Route
            exact
            path="/admin/manage-testimonials"
            element={
              <ProtectedRoutes>
                <ManageTestimonials />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default memo(App);
