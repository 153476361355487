import React, { useEffect, useState } from "react";
import Styled from "./styles";
import { Grid, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import action from "../../../../redux/action";
const rowsSet = {
  1: ["11", "12", "13"],
  2: ["21", "22", "23"],
  3: ["31", "32", "33"],
};
const colsSet = {
  1: ["11", "21", "31"],
  2: ["12", "22", "32"],
  3: ["13", "23", "33"],
};
const ResultArea = ({
  playGroundElems,
  usersTurn,
  updateComputerEntry,
  newGame,
  roundWinner
}) => {
  const [winner, setWinner] = useState(false);
  const playWith = useSelector((state) => state?.manageGameData?.playWith);
  const gameScore = useSelector((state) => state?.manageGameData?.score);
  const dispatch = useDispatch();

  const checkForWinner = () => {
    const sortedOrder = {};
    let winningSet = [];
    playGroundElems?.map((elemsObjs, index) => {
      Object.keys(elemsObjs)?.map((keys) => {
        sortedOrder[keys] = elemsObjs?.[keys];
        if (usersTurn?.position === keys) {
          const keyArrays = keys.split("");

          winningSet.push(rowsSet?.[keyArrays[0]]);
          winningSet.push(colsSet?.[keyArrays[1]]);
          if (keyArrays[0] === keyArrays[1]) {
            winningSet.push(["11", "22", "33"]);
          }
          if (
            (keyArrays.includes("1") && keyArrays.includes("3")) ||
            keys === "22"
          ) {
            winningSet.push(["13", "22", "31"]);
          }
        }
      });
    });
    let getWinDetails;

    winningSet.map((elArray) => {
      let counter = 0;
      elArray.filter((value, i) => {
        if (sortedOrder[value] === usersTurn?.pressed) {
          counter++;
        }
        if (counter === 3) {
          getWinDetails = value;
        }
      });
    });
    if (getWinDetails) {
      roundWinner(usersTurn?.pressed);
      setWinner(usersTurn?.pressed);
    } else {
      if (playWith === "Computer") {
        setTimeout(() => {
          if (usersTurn?.pressed === "O") {
            let computerPressed = "";
            for (let keys in sortedOrder) {
              if (sortedOrder?.[keys] === "") {
                computerPressed = keys;
                break;
              }
            }
            updateComputerEntry(computerPressed);
          }
        }, 100);
      }
    }
  };
  const resetGame = () => {
    dispatch(action.resetGame());
  };
  useEffect(() => {
    checkForWinner();
  }, [usersTurn]);

  useEffect(() => {
    if (winner) {
      dispatch(action.score(usersTurn?.pressed));
    }
  }, [winner]);
  return (
    <Styled>
      <Grid container columns={{ xs: 4, md: 12 }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {winner && <>Winner: {winner}</>}
            </Grid>
            <Grid item xs={6}>
              <div className="score-card">
                <strong>Score : </strong>
                <br></br>X : {gameScore.X} <br></br>O : {gameScore.O}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => resetGame()}
              >
                Menu / Reset Game
              </Button>
            </Grid>
            <Grid item xs={6}>
              {usersTurn?.position !== "00" && (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setWinner(false);
                    newGame();
                  }}
                >
                  Next Round
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Styled>
  );
};

export default ResultArea;
