import React, { memo, useEffect, useMemo } from "react";
import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import TestimonialsExtra from "../TestimonialsExtra";
import { Grid } from "@mui/material";
import IconComponent from "../../common/Icons";
import { useSelector } from "react-redux";
import DynamicForm from "../DynamicForm";
import { EnquiryFormObjs } from "../../common/formJson/EnquiryFormObjs";
const contactData = [
  {
    icon: "BsMailbox",
    title: "Email",
    type: "email",
    data: "ersvweb@gmail.com",
  },
  {
    icon: "MdOutlinePhone",
    title: "Phone",
    type: "phone",
    data: ["+91-8286813508", "+91-8879487285"],
  },
];
const Contact = () => {
  const theme = useSelector(({ config }) => config?.theme);

  const EnquiryFormObjsNew = useMemo(() => EnquiryFormObjs, []);
  return (
    <Styled>
      <MainContainer padding="0">
        <TestimonialsExtra />
        <Grid container className="contact-container" id="contact">
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <h4 className="sub-text">Work Enquiry</h4>
              <h3 className="second-heading">
                Let's work together and i'll make my best!
              </h3>
              <div className="marginTop-20px">
                {contactData?.map(({ icon, title, data, type }) => (
                  <div className="marginTop-30px" key={icon}>
                    <div className="services-wrapper">
                      <div className="service-icon">
                        <IconComponent
                          iconName={icon}
                          iconColor={theme?.colors?.["0"]}
                          avtarObjs={{
                            bgColor: theme?.colors?.["2"],
                            width: "50px",
                          }}
                        />
                      </div>
                      <div className="services-text dark">
                        <strong>{title}</strong>
                        <div>
                          {Array.isArray(data) ? (
                            data?.map((content,index) => (
                              <div key={index}>
                                {type === "email" && (
                                  <a href={`mailto:${content}`}>{content}</a>
                                )}
                                {type === "phone" && (
                                  <a href={`tel:${content}`}>{content}</a>
                                )}
                                <br/>
                              </div>
                            ))
                          ) : (
                            <>
                              {type === "email" && (
                                <a href={`mailto:${data}`}>{data}</a>
                              )}
                              {type === "phone" && (
                                <a href={`tel: ${data}`}>{data}</a>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="services-wrapper">
                  <div className="service-icon">
                    <IconComponent
                      iconName={"BsMailbox"}
                      iconColor={theme?.colors?.["0"]}
                      avtarObjs={{ bgColor: theme?.colors?.["2"], width: "50px" }}
                    />
                  </div>
                  <div className="services-text dark">ersvweb@gmail.com</div>
                </div> */}
              </div>
              {/* <div className="marginTop-20px">
                <div className="services-wrapper">
                  <div className="service-icon">
                    <IconComponent
                      iconName={"IoShareSocialOutline"}
                      iconColor={theme?.colors?.["0"]}
                      avtarObjs={{ bgColor: theme?.colors?.["2"], width: "50px" }}
                    />
                  </div>
                  <div className="services-text dark">
                    <a style={{'padding-left':'0'}}>
                    <IconComponent
                      iconName={"FaTwitter"}
                      iconColor={theme?.colors?.["1"]}
                      iconFontSize={theme?.fonts?.['2']}
                    />
                    </a>
                    <a>
                    <IconComponent
                      iconName={"FaInstagram"}
                      iconColor={theme?.colors?.["1"]}
                      iconFontSize={theme?.fonts?.['2']}
                    />
                    </a>
                    <a>
                    <IconComponent
                      iconName={"FaYoutube"}
                      iconColor={theme?.colors?.["1"]}
                      iconFontSize={theme?.fonts?.['2']}
                    />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DynamicForm formObj={EnquiryFormObjsNew} />
          </Grid>
        </Grid>
      </MainContainer>
    </Styled>
  );
};

export default memo(Contact);
