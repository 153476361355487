'use client'
import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";

const RichTextEditorInput = ({
  formId,
  name,
  helperText,
  required,
  placeholder = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({ errorFlag: false, errorType: false });
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );
  const editor = useRef(null);
  const onChange = (content) => {
    if(content?.name){
      name=content?.name;
      content=content?.value;
    }
    dispatch(action.passedFormValidation(false, name, formId));
    let errorFlag = false;
    let errorType = false;
    dispatch(action.updateDynamicFormFields(name, content, formId));
    if (required && !content?.length) {
      errorFlag = true;
      errorType = 1;
    } else if (
      dynamicForms?.fieldsObj?.[name]?.regEx &&
      !dynamicForms?.fieldsObj?.[name]?.regEx.test(content)
    ) {
      errorFlag = true;
      errorType = 2;
    } else {
       errorFlag = false;
      errorType = false;
    }
    setError({ errorFlag: errorFlag, errorType: errorType });
    if (!errorFlag) {
      dispatch(action.passedFormValidation(!error?.errorFlag, name, formId));
    }
  };
  useEffect(() => {
    if (dynamicForms?.submit === true) {
      const data = {
        name: dynamicForms?.fieldsObj?.[name].name,
        value: dynamicForms?.inputValObjs?.[name],
      };
      onChange(data);
    }
  }, [dynamicForms?.submit]);
  return (
    <Styled>
      <JoditEditor
        ref={editor}
        value={dynamicForms?.inputValObjs?.[name]}
        onChange={(newContent) => onChange(newContent)}
        {...props}
      />
      {error?.errorFlag && <span className="error">{helperText?.[error?.errorType]}</span>}
    </Styled>
  );
};

export default React.memo(RichTextEditorInput);
