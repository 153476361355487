import styled from "styled-components/macro";

export default styled.div`
.pnf-container{
  height: 500px !important;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
`;
