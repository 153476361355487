import Styled from "./styles";
import MainContainer from "../../common/MainContainer";
import { Grid } from "@mui/material";
import SubHeading from "../../common/SubHeadings";
import IconComponent from "../../common/Icons";
import { useSelector } from "react-redux";

const testimonies = [
  {
    icon: "FaConnectdevelop",
    title: "Web Development",
    // desc: "I specialize in designing and developing professional websites that are tailored to meet your unique needs and goals. From initial strategy and planning, to launch and ongoing support, I'm here to help every step of the way.",
  },
  {
    icon: "GiLargePaintBrush",
    title: "Web Designing",
    // desc: "I design websites that look and work great on all devices, from desktops and laptops to smartphones and tablets.",
  },
  {
    icon: "GiAgave",
    title: "Web App Maintenance",
    // desc: "I offer ongoing maintenance and support services to keep your website running smoothly and secure, so you can focus on growing your business.",
  },
];
const Services = () => {
  const theme = useSelector(({ config }) => config?.theme);
  return (
    <Styled id="services">
      <MainContainer padding="10px 0 10px 0">
        <Grid container className="services-container">
          <SubHeading fpart="What we offer" spart="Services" />
          {testimonies?.map((obj, index) => (
            <Grid item xs={12} sm={4} className="service-grids" key={index}>
              <div className="fun-facts-wrapper">
                <IconComponent
                  iconName={obj?.icon}
                  iconColor={theme?.colors?.["1"]}
                  avtarObjs={{ bgColor: theme?.colors?.["7"], width: "100px" }}
                />
                <div className="text-content">
                  <h1 className="fun-number">
                    {obj?.title}
                    <br></br>
                  </h1>
                  {/* <p className="paragraph-white"> {obj?.desc}</p> */}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </MainContainer>
    </Styled>
  );
};

export default Services;
