import styled from "styled-components/macro";

export default styled.div`
  label {
    width: 100%;
  }
  .MuiButtonBase-root {
    width: 100%;
  }
  .error-input {
    border: 2px solid #d32f2f !important;
  }
  .error-span {
    font-size: 0.75rem;
    color: #d32f2f;
    float: left;
    padding: 2px 0 0 15px;
    font-weight: 400;
    font-family:${({ theme }) => theme?.fontFamily?.["0"]} !important;
  }
`;
