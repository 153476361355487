import styled from "styled-components/macro";

export default styled.div`
  position: relative;
 
  padding: ${({ padding }) => padding};
   @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    padding: 0;
  }
  background-color: ${({ theme,bgColor }) => theme?.colors?.[bgColor]};
  .main-container {
    max-width: 1235px;
  }
`;
