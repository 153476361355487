import React, { useEffect,useCallback } from "react";
import Styled from "./styles";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";
import { handlerResponseAlerts } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
const ButtonInput = ({
  label,
  formId,
  loaderLabel,
  variant = "outlined",
  service,
  editRecordId=false,
  ...props
}) => {
  const dispatch = useDispatch();
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );
  const navigate = useNavigate();

  const triggerDServiceCall=()=>{
    if (service?.url) {
      const { url, method } = service;
      const formParams=service?.params||{};
      if(editRecordId){
        Object.assign(formParams,{id:editRecordId});
      }
      dispatch(
        action.dynamicFormServiceCall(
          {...dynamicForms?.inputValObjs,schema:service?.schema},
          method,
          editRecordId?service?.editUrl:url,
          formId,
          formParams
        )
      );
    }
  }
  const triggerEServiceCall=()=>{
    if (service?.emailService) {
      dispatch(action.emailServiceCall(dynamicForms?.inputValObjs, formId));
    }
  }
  const onClickHandler =  useCallback((e) => {
    e.preventDefault();
     dispatch(action.setSubmitForm(formId));
    if (checkFormValidation()) {
      triggerEServiceCall();
      triggerDServiceCall();
    }
  });
useEffect(()=>{
  if(service?.Autoload){
   triggerDServiceCall();
  }
},[service?.Autoload])

  useEffect(() => {
    if (dynamicForms?.results?.status === 200) {
      if (service?.errorParams) {
        const resCode = handlerResponseAlerts(
          service?.errorParams,
          dynamicForms?.results
        );
        if (resCode === 3) {
          if (formId === "loginForm"&&dynamicForms?.results?.data[0]) {
            sessionStorage.setItem(
              "loginData",
              JSON.stringify({...dynamicForms?.results?.data[0],token:dynamicForms?.results?.token})
            );
          }
          if (service?.gotoLink) {
            navigate(service?.gotoLink);
          }
        }
      }
    }
  }, [dynamicForms?.results]);

  const checkFormValidation = useCallback(() => {
    for (let key in dynamicForms?.validationPassed) {
      if (dynamicForms?.validationPassed?.[key] === false) {
        return false;
      }
    }
    return true;
  },[dynamicForms?.validationPassed])
  return (
    <Styled>
      <Button
        variant={variant}
        {...props}
        onClick={onClickHandler}
        disabled={dynamicForms?.loadingData}
      >
        {dynamicForms?.loadingData ? <Loader /> : editRecordId?props?.editLabel:label}
      </Button>
    </Styled>
  );
};

export default React.memo(ButtonInput);
