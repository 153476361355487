import React, { useState, useEffect, memo, useMemo } from "react";
import Styled from "./styles";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../redux/action";

const TextFieldInput = ({
  helperText,
  formId,
  name,
  regEx,
  maxLength = false,
  InputLabelProps = {},
  ...props
}) => {
  const [error, setError] = useState({ errorFlag: false, errorType: false });
  const dispatch = useDispatch();
  const dynamicForms = useSelector(
    ({ dynamicForms }) => dynamicForms?.[formId]
  );

  const Submit = useMemo(() => dynamicForms?.submit, [dynamicForms?.submit]);
  const FieldsObj = useMemo(
    () => dynamicForms?.fieldsObj,
    [JSON.stringify(dynamicForms?.fieldsObj)]
  );
  const InputValObjs = useMemo(
    () => dynamicForms?.inputValObjs,
    [JSON.stringify(dynamicForms?.inputValObjs)]
  );
  const onChangeHandler = (e) => {
    dispatch(action.passedFormValidation(false, e.target.name, formId));
    let errorFlag = false;
    let errorType = false;
    dispatch(
      action.updateDynamicFormFields(e.target.name, e.target.value, formId)
    );
    if (FieldsObj?.[name]?.required && !e.target.value) {
      errorFlag = true;
      errorType = 1;
    } else if (
      e.target.value &&
      FieldsObj?.[name]?.regEx &&
      !FieldsObj?.[name]?.regEx.test(e.target.value)
    ) {
      errorFlag = true;
      errorType = 2;
    } else {
      errorFlag = false;
      errorType = false;
    }
    setError({ errorFlag: errorFlag, errorType: errorType });
    if (!errorFlag) {
      dispatch(action.passedFormValidation(!error?.errorFlag, e.target.name, formId));
    }
  };

  useEffect(() => {
    if (Submit === true) {
      const e = {};
      e.target = {
        name: FieldsObj?.[name].name,
        value: InputValObjs?.[name],
      };
      onChangeHandler(e);
    }
  }, [Submit]);
  return (
    <Styled>
      <div>
        <TextField
          key={name}
          error={error?.errorFlag}
          variant="outlined"
          onChange={onChangeHandler}
          onBlur={onChangeHandler}
          helperText={error?.errorFlag ? helperText?.[error?.errorType] : false}
          name={name}
          InputLabelProps={{ ...InputLabelProps }}
          value={InputValObjs?.[name]}
          inputProps={{ maxLength: maxLength }}
          {...props}
        />
      </div>
    </Styled>
  );
};

export default memo(TextFieldInput);
