import styled from "styled-components/macro";

export default styled.div`
  .works-container {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    text-align: center;
    padding: 20px 24px 50px 24px;
  }
  .align-center {
    text-align: center;
  }
  .work-ele {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
  }
  .work-ele a {
    position: relative;
    overflow: hidden;
  }
  .work-ele a div {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }

  @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    .triangle-up {
      top: 6px !important;
    }
  }
  .triangle-parent-div {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .triangle-up {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 35px solid ${({ theme }) => theme?.colors?.["0"]};
  }
  .work-ele img {
    width: 100%;
  }

  .work-ele {
    position: relative;
    transition: 0.5s ease;
    .work-content {
      position: absolute;
      width: 100%;
      @media (min-width: ${({ theme }) => theme?.deviceSize?.desktop}) {
        height: 100%;
        top: 0;
        display: none;
      }
      @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
        height: auto;
        top: none;
        bottom: 0;
        display: flex;
        padding-bottom: 2px;
        background-image: linear-gradient(
          to right,
          rgb(1, 183, 255, 0.5),
          rgb(68, 240, 181, 1)
        );
        > .inner {
          color: ${({ theme }) => theme?.colors?.["0"]};
          margin: auto;
          > div {
            padding: 10px;
          }
          a {
            background-color: ${({ theme }) => theme?.colors?.["7"]};
            padding: 10px;
            color: ${({ theme }) => theme?.colors?.["1"]};
            text-decoration: none;
            border: 2px solid ${({ theme }) => theme?.colors?.["1"]};
            border-radius: 25px;
          }
        }
      }
      &:hover {
        background-image: linear-gradient(
          to right,
          rgb(1, 183, 255, 0.5),
          rgb(68, 240, 181, 1)
        );
      }
    }
    &:hover .work-content {
      display: flex;
      > .inner {
        color: ${({ theme }) => theme?.colors?.["0"]};
        margin: auto;
        display: inline;
        text-align: center;
        > div {
          padding: 10px;
        }
        a {
          background-color: ${({ theme }) => theme?.colors?.["7"]};
          padding: 10px;
          color: ${({ theme }) => theme?.colors?.["1"]};
          text-decoration: none;
          border: 2px solid ${({ theme }) => theme?.colors?.["1"]};
          border-radius: 25px;
        }
      }
    }
  }
`;
