
import {errorObjs} from '../CommonObj'

export const AddAndUpdateTestimonials = {
  formId: "AddAndUpdateTestimonials",
  formInp: [
    {
      inp: {
        InputLabelProps: {
          shrink: true,
        },
        type: "text",
        label: "Client Name",
        name: "client_name",
        id: "client_name",
        required: true,
        fullWidth: true,
        helperText: errorObjs,
      },
      grid: 12,
    },
   
    {
      inp: {
        InputLabelProps: {
          shrink: true,
        },
        type: "text",
        label: "Feedback Description",
        name: "feedback_desc",
        id: "feedback_desc",
        rows: 6,
        required: true,
        multiline: true,
        fullWidth: true,
        helperText: errorObjs,
      },
      grid: 12,
    },
    {
      inp: {
        hidden: true,
        type: "file",
        accept: "image/*",
        label: "Choose Client Image",
        name: "client_image",
        id: "raised-button-file",
        // helperText: errorObjs,
        // required: true,
      },
      grid: 12,
    },

    {
      inp: {
        type: "alert",
      },
      grid: 12,
    },
    {
      inp: {
        type: "submit",
        label: "Add testimonials",
        editLabel: "Update testimonials",
        loaderLabel: "Adding data...",
        name: "add",
        id: "add",
        variant: "contained",
        className: "btn-std1",
        fullWidth: true,
        service: {
          schema: "testimonialsSchema",
          editUrl:process.env.REACT_APP_API_SERVICE_URL + `api/update_data`,
          url: process.env.REACT_APP_API_SERVICE_URL + `api/add_data`,
          method: "POST",
        },
      },
      grid: 12,
    },
  ],
};
