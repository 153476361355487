import React from "react";
import Styled from "./styles";
import { Grid } from "@mui/material";
import IconComponent from "../../common/Icons";
import { useSelector } from "react-redux";
import { getCurrentDate } from "../../utils/Utils";
const testimonies = [
  {
    icon: "MdOutlineWeb",
    title: "Projects done",
    nums: "70+",
  },
  {
    icon: "MdOutlineCode",
    title: "Lines of code",
    nums: "∞",
  },
  {
    icon: "FiCoffee",
    title: "Cups of coffee",
    nums: "2000+",
  },
  {
    icon: "MdOutlinePermContactCalendar",
    title: "Years of experience",
    nums: (getCurrentDate()-2015)+"+",
  },
];

const Testimonials = () => {
  const theme = useSelector(({ config }) => config?.theme);

  return (
    <Styled >
      <Grid container className="testimonials-container">
        {testimonies?.map((obj,index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <div className="fun-facts-wrapper">
              <IconComponent
                iconName={obj?.icon}
                iconColor={theme?.colors["0"]}
                iconFontSize={theme?.fonts["11"]}
              />
              <div className="text-content">
                <h1
                  className="fun-number"
                  style={obj?.nums === "∞" ? { "font-size": "80px" } : {}}
                >
                  {obj?.nums}
                  <br></br>
                </h1>
                <p className="paragraph-white">{obj?.title}</p>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Styled>
  );
};

export default React.memo(Testimonials);
