import styled from "styled-components/macro";

export default styled.div`
width : 100%;
.sub-heading {
  text-align: center;
}
.sub-heading .sub-text {
  color: ${({ theme }) => theme?.colors?.["2"]};
}
.sub-heading .second-heading{
  color:${({ sColSwap,theme }) => theme?.colors?.[sColSwap?"1":"0"]} !important;
}
`;
