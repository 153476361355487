import { useNavigate } from "react-router-dom";
import Config from "./Config";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();

  let auth = JSON.parse(sessionStorage.getItem("loginData") || "{}");

  if (!auth?.token || !auth) {
    return navigate(Config?.pageConfigs?.LogoutRedirectPage);
  }

  return <>{children}</>;
};
export default ProtectedRoutes;
