import Styled from "./styles";
import Header from "../Header";
import { Grid } from "@mui/material";
const PageNotFound = () => {
  return (
    <Styled>
      <Header />
      <Grid container>
        <Grid item xs={12} sm={4} lg={12}>
          <div className="pnf-container">404 Error | Page Not Found!</div>
        </Grid>
      </Grid>

    </Styled>
  );
};

export default PageNotFound;
