import styled from "styled-components/macro";

export default styled.div`
  .services-container {
    background-color: ${({ theme }) => theme?.colors?.["1"]};
    text-align: center;
    padding: 0 20px 40px 20px;
    color: ${({ theme }) => theme?.colors?.["0"]};
  }
  .work-ele a {
    position: relative;
    overflow: hidden;
    float: left;
  }
  .work-ele a div {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }
  h1 {
    font-size: ${({ theme }) => theme?.fonts?.["3"]} !important;
  }
  .service-grids:hover .MuiAvatar-root {
    transition: 0.4s;
    filter: drop-shadow(0 0 4px #ff0000) drop-shadow(0 0 4px #ff7f00)
      drop-shadow(0 0 4px #ffff00) drop-shadow(0 0 4px #00ff00)
      drop-shadow(0 0 4px #0000ff) drop-shadow(0 0 4px #2e2b5f)
      drop-shadow(0 0 4px #8b00ff);
  }
`;
