import {errorObjs} from './CommonObj'

export const EnquiryFormObjs = {
  formId: "enquiryForm",
  formInp: [
    {
      inp: {
        InputLabelProps:{
          shrink: true
        },
        type: "text",
        label: "First Name",
        name: "firstName",
        id: "firstName",
        fullWidth: true,
        required: true,
        helperText: errorObjs,
        maxLength: 15,
      },
      grid: 6,
    },
    {
      inp: {
        InputLabelProps:{
          shrink: true
        },
        type: "text",
        label: "Last Name",
        name: "lastName",
        id: "lastName",
        fullWidth: true,
        required: true,
        helperText: errorObjs,
        maxLength: 15,
      },
      grid: 6,
    },
    {
      inp: {
        InputLabelProps:{
          shrink: true
        },
        type: "text",
        label: "Phone Number",
        name: "phone",
        id: "phone",
        fullWidth: true,
        required: true,
        regEx: /^\d{10}$/,
        helperText: errorObjs,
        maxLength: 10,
      },
      grid: 6,
    },
    {
      inp: {
        InputLabelProps:{
          shrink: true
        },
        type: "email",
        label: "Email",
        name: "email",
        id: "email",
        regEx: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
        fullWidth: true,
        helperText: errorObjs,
      },
      grid: 6,
    },
    {
      inp: {
        InputLabelProps:{
          shrink: true
        },
        type: "text",
        label: "Project Details",
        name: "projectDetails",
        id: "projectDetails",
        rows: 6,
        multiline: true,
        fullWidth: true,
        helperText: errorObjs,
      },
      grid: 12,
    },
    {
      inp: {
        type: "alert",
      },
      grid: 12,
    },
    {
      inp: {
        type: "submit",
        label: "Submit Enquiry!",
        loaderLabel: "Sending enquiry...",
        name: "enquiry_submit",
        id: "enquiry_submit",
        className: "btn-std",
        size:'large',
        service:{
          schema: "enquiriesSchema",
          emailService:true,
          url:process.env.REACT_APP_API_SERVICE_URL+`api/add_data`,
          method:'POST',
        }
      },
      grid: 12,
    },
  ],
};
