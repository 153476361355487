import styled from "styled-components/macro";

export default styled.div`
text-align:center;
padding: 40px 0;
background-color: ${({ theme }) => theme?.colors?.["2"]};
background-image: linear-gradient(22deg, ${({ theme }) => theme?.colors?.["2"]}, ${({ theme }) => theme?.colors?.["3"]});
.testimonials-container{
    
}
.testimonials-container .text-content{
color:${({ theme }) => theme?.colors?.["0"]};
}
}
.fun-number {
    font-family: ${({ theme }) => theme?.fontFamily?.["0"]};
    margin-top: 10px;
    margin-bottom: 7px;
    color: #fff;
    font-size: ${({ theme }) => theme?.fonts?.["7"]};
    line-height: 50px;
    font-weight: 600;
}
p {
    margin-bottom: 10px;
    font-family: ${({ theme }) => theme?.fontFamily?.["0"]};
    font-size: 17px;
    line-height: 24px;
}
`;
