import emailjs from "@emailjs/browser";
import axios from "axios";

const apiCalls = {
  sendEmail: (formDataObj, formID) => {
    return emailjs.send(
      "service_do0f1h7",
      "template_zc3r7u4",
      formDataObj,
      "APQ3Os04dLFkzM3G4"
    );
  },
  dynamicFormService: (
    formDataObj,
    method,
    url,
    formID,
    formParams = false
  ) => {
    let loginData = sessionStorage.getItem("loginData");
    loginData = JSON.parse(loginData);
    const headers={"Content-Type": "application/json"};
    if (loginData?.token) {
      headers['Authorization']=loginData?.token;
    }
    if (method === "GET") {
      const response = axios.get(url, { headers: headers||{}, params: formParams });
      return response;
    }
    let newFormDataObjs = formDataObj;
    if (formParams) {
      newFormDataObjs = Object.assign(formDataObj, formParams);
    }
    if (method === "POST") {
      headers['Content-Type']='multipart/form-data';
      let formData = new FormData();
      for (let key in newFormDataObjs) {
        formData.append(key, newFormDataObjs?.[key]);
      }
      const response = axios.post(url, formData,{
        headers: {...headers}
      });
      return response;
    }
  },
};
export default apiCalls;
