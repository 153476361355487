const Config = {
  Theme: [
    {
      colors: {
        0: "#fff",
        1: "#000",
        2: "#01b7ff",
        3: "#44f0b5",
        4: "#01b7ff",
        5: "#666",
        6: "#f6c214",
        7: "#996600",
        8:"transparent",
        9:"gray"
      },
      fonts: {
        0: "14px",
        1: "16px",
        2: "18px",
        3: "20px",
        4: "21px",
        5: "38px",
        6: "45px",
        7: "48px",
        8: "50px",
        9: "80px",
        10: "55px",
        11: "75px",
        12: "10px",
        13: "32px",
      },
      fontFamily:{
        1:"Roboto Slab, sans-serif",
        0:"Roboto Slab, sans-serif"
      },
      deviceSize: {
        mobile: "600px",
        tablet: "900px",
        desktop: "1025px",
      },
    },
  ],
  pageConfigs: {
    LogoutRedirectPage: "/login",
  },
};
module.exports = Config;
